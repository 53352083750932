import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import Logo from "../images/logo192.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import usePasswordToggle from "../hooks/usePasswordToggle";
import PasswordStrengthIndicator from "../PasswordStrengthIndicator";
import "./signup.css";

import {Grid, Typography, Box, Modal, CardContent, CardActions, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const isNumberRegx = /\d/;
const specialCharacterRegx = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

export default function Signup() {
  const { signup } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [msg, setMsg] = useState("");
  const [email, setEmail] = useState("");

  const onChangeEmail = email => {
    setEmail(email);
  };

  async function handleSubmit(e) {
    e.preventDefault()

    if (password !== passwordConfirm) {
      return setError("Passwords do not match")
    }

    try {
      setError("")
      setLoading(true)
      await signup(email, password)
      history.push("/")
    } catch {
      setError("Email Exist")
    }

    setLoading(false)
  }

    const [PasswordInputType, ToggleIcon] = usePasswordToggle();
    const [passwordFocused, setPasswordFocused] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [passwordValidity, setPasswordValidity] = useState({
        minChar: null,
        number: null,
        specialChar: null
    });

    const onChangePassword = password => {
        setPassword(password);

        setPasswordValidity({
            minChar: password.length >= 8 ? true : false,
            number: isNumberRegx.test(password) ? true : false,
            specialChar: specialCharacterRegx.test(password) ? true : false
        });
    };

    const onChangePasswordConfirm = password => {
        setPasswordConfirm(password);
    };

  return (
    <>
      <div className="pt-5 row justify-content-center">
        <div className="pt-5 col-lg-6 text-center">

            <div className="card bg-light">
                <article
                    className="card-body mx-auto"
                    style={{ maxWidth: "400px" }}
                >
                    <h4 className="card-title mt-3 text-center">
                        Create Account
                    </h4>
                    <p className="text-center">
                    {error && <Alert variant="danger">{error}</Alert>}
                    </p>

                    <form onSubmit={handleSubmit}>
                        <div className="form-group input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <FontAwesomeIcon icon="envelope" />
                                </span>
                            </div>
                            <input
                                name=""
                                className="form-control"
                                placeholder="Email address"
                                type="email"
                                onChange={e =>
                                onChangeEmail(e.target.value)
                            }
                            />
                        </div>

                        <div className="form-group input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <FontAwesomeIcon icon="lock" />
                                </span>
                            </div>
                            <input
                                className="form-control"
                                placeholder="Create password"
                                type={PasswordInputType}
                                value={password}
                                onFocus={() => setPasswordFocused(true)}
                                onChange={e =>
                                    onChangePassword(e.target.value)
                                }
                            />
                            <span className="password-toogle-icon">
                                {ToggleIcon}
                            </span>
                        </div>

                        <div className="form-group input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <FontAwesomeIcon icon="lock" />
                                </span>
                            </div>
                            <input
                                className="form-control"
                                placeholder="Create password"
                                type={PasswordInputType}
                                value={passwordConfirm}
                                onFocus={() => setPasswordFocused(true)}
                                onChange={e =>
                                    onChangePasswordConfirm(e.target.value)
                                }
                            />
                            <span className="password-toogle-icon">
                                {ToggleIcon}
                            </span>
                        </div>

                        {passwordFocused && (
                            <PasswordStrengthIndicator
                                validity={passwordValidity}
                            />
                        )}

                        <div className="form-group">
                            <button
                                type="submit"
                                className="btn btn-primary btn-block"
                            >
                                {" "}
                                Create Account{" "}
                            </button>
                        </div>

                        <p className="text-center">
                        By signing up, you agree to our <Link onClick={handleOpen}>Terms and Data Policy</Link>
                        </p>

                        
                        <p className="text-center">
                            Have an account? <Link to="/login">Log In</Link>
                        </p>
                    </form>
                </article>
            </div>
        </div>
    </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content py-md-5 px-md-4 p-sm-3 p-4">
            <h3 class="r3 px-md-5 text-justify px-sm-1">Data Privacy Act</h3>
            <p class="r3 px-md-5 text-justify px-sm-1">WADCAS is duly obligated and responsible under the Republic Act no. 1073 known as data privacy act of 2012 and its implementing rules and regulations, 
                  in order to protect and ensure the privacy, security, safety and process all personal sensitive information. 
                  Data Privacy Act of 2012 ensures the confidentiality and security of any personal information (personal data, sheets, and email) 
                  subject to compliance with the requirements of this Act and other laws allowing disclosure of information to the public and adherence to the principal of transparency, 
                  legitimate purpose and proportionality.
                  Rest assured that the authorized WADCAS admin are allowed to hold your personal information only part of their duties and responsibility.</p>
        </div>
    </div>
      </Modal>
    </>
  )
}
