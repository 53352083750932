import firebase from "firebase/app"
import "firebase/auth"
import 'firebase/firestore'

const app = firebase.initializeApp({
  apiKey: "AIzaSyCnBT-adtjCnJ7828rqHK_8oV_Sp9NeNPY",
  authDomain: "wadcas-users-auth.firebaseapp.com",
  projectId: "wadcas-users-auth",
  storageBucket: "wadcas-users-auth.appspot.com",
  messagingSenderId: "69813635475",
  appId: "1:69813635475:web:5832604aeb57e50b11c066"
})

export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = app.auth();
export const db = app.firestore();
export default app
