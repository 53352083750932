import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
// import AppConfig from "App.config";
const Home = ({ children }) => {
    return (
        <>
            <div className="wrapper">

                <div id="content">

                    {children}
                </div>
            </div>

        </>
    );
};

export default withRouter(Home);
