import React from "react"
import Signup from "./Signup"
import { Container } from "react-bootstrap"
import { AuthProvider } from "../contexts/AuthContext"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Admin from "./Admin"
import Login from "./Login"
import PrivateRoute from "./PrivateRoute"
import ForgotPassword from "./ForgotPassword"
import UpdateProfile from "./UpdateProfile"
import QRgen from "./QRgenerator"
import QRscan from './QRscanner'
import UserSignUp from './UserSignUp'

import "../FontawsomeIcons";

import "./App.css"
import "./dark.css";

import Layout from "../pages/_layouts/Home";

function App() {
  return (
    <Router>
      <Layout>
      <AuthProvider>
        <Switch>
          <PrivateRoute exact path="/" component={QRscan} />
          <PrivateRoute path="/update-profile" component={UpdateProfile} />
          <Route path="/signup" component={Signup} />
          <Route path="/login" component={Login} />
          <Route path="/forgot-password" component={ForgotPassword} />
        </Switch>
      </AuthProvider>
      </Layout>
    </Router>
  )
}

export default App
