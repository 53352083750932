import React, {useState} from 'react'
import {Fab, TextareaAutosize} from '@material-ui/core'
import {ArrowBack} from '@material-ui/icons'
import { Link, useHistory } from "react-router-dom";
import QrScan from 'react-qr-reader'
import { useAuth } from "../contexts/AuthContext"
import {Grid, Typography, Box, Modal, Card, CardContent, CardActions, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import axios from 'axios';
import { Button } from "react-bootstrap"
import Logo from "../images/logo-colored.png"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  marginTop: '100px',
  
  backgroundColor: 'tomato'
};

const buttonLogStyle = {
  marginTop: '20px',
};



function QRscanner() {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [msg, setMsg] = useState("");
  const [scanned, setScanned] = useState(false);
    
  const history = useHistory()
  const { currentUser, logout } = useAuth()
  const [error, setError] = useState("")
  const [qrscan, setQrscan] = useState('');
  const [dpa, setDpa] = useState(false);
  const [primaryMsg, setPrimaryMsg] = useState('');
  const [secondaryMsg, setSecondaryMsg] = useState('');
  const [headerMsg, setHeaderMsg] = useState('');
  const [primary1, setPrimary1] = useState('');
  const [primary2, setPrimary2] = useState('');
  const [primary3, setPrimary3] = useState('');
  const [exist, setExist] = useState(false);

  // function for handling qr validation
  const handleScan = data => {
    setScanned(true);
    console.log(data);
    // if data has value
    if (data) {
      // proceed checking validation
      axios.get('https://wadcas-be.herokuapp.com/certificate/chunk/' + data)
      // axios.get('http://localhost:5000/certificate/chunk/' + data)
        .then(res => {
            if (res.data) {
              console.log(res.data);
              setPrimary1('This Certificate Belongs to:' + res.data.fullname);
              setPrimary2('Document Type:' + res.data.document_type);
              setPrimary3('Program Type:' + res.data.program);
              setHeaderMsg('Certificate Found!');
              setExist(true);
              handleOpen();
            } 

            if (res.data != null && !res.data) {
              console.log('res doesnt exist')
              setHeaderMsg('Certificate not Found!');
              setPrimary1('This Certificate Doesnt Belong To Anyone');
              setPrimary2('The Certificate Might Be Deleted');
              setPrimary3('Wrong QR Code');
              setExist(false);
              handleOpen();
            }
        })
        .catch(error => {
          setHeaderMsg('This Certificate Does Not Exist');
          setPrimary1('This Certificate Doesnt Belong To Anyone');
          setPrimary2('The Certificate Might Be Deleted');
          setPrimary3('Wrong QR Code');
          setExist(false);
            handleOpen();
            console.log(error);
        })
      }
  }

  const handleDpa = () => {
    setDpa(true);
  }

  const handleScannerState = () => {
    setScanned(false)
  }

  const handleScanOff = () => {
    // console.log('waiting . . .')
  }

    const handleError = err => {
    console.error(err)
    }

    async function handleLogout() {
      setError("")
  
      try {
        await logout()
        history.push("/login")
      } catch {
        setError("Failed to log out")
      }
    }

    return (
      <>
      <div>
        <center>
          <div className="mt-5">
          <h4 className="card-title mt-3 text-center">
                  <img style={{ height: "100px", width: "100px" }} src={Logo} class="card-img-top" alt="..."/>
                      
                      
                  </h4>
            <span>This QR Scanner is Intended for TSU-CCS Students</span>
            
            {/* QR scanner component */}
              <QrScan
                delay={300}
                onError={handleError}
                onScan={scanned ? handleScanOff : handleScan}
                style={{ height: 240, width: 320 }}
              />
          </div>
        </center> 
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Notification
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {msg}
          </Typography>
        </Box>
      </Modal>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar style={{ background: exist ? 'blue' : '#f43f5e' }} sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {headerMsg}
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          <ListItem button>
            <ListItemText primary={primary1} secondary="Holder" />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText
              primary={primary2}
              secondary=""
            />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText
              primary={primary3}
              secondary=""
            />
          </ListItem>
        </List>
      </Dialog>
    </div>

    <div>
      
      <div className="text-center mt-100">
        <Button style={buttonStyle} onClick={handleScannerState}  className="mt-100 w-50">
          Scan
        </Button>
      </div>

      <div className="text-center">
        <Button style={buttonLogStyle} onClick={handleLogout} className="w-50">
          Logout
        </Button>
      </div>
    </div>
    </>
    );
  }
  
  export default QRscanner;
  
